import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // all securities
    getAllSecurities(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getAllSecurities`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    // all verified securities
    getAllVerifiedSecurities(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getAllVerifiedSecurities`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    // all unverified securities
    getAllUnverifiedSecurities(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getAllUnverifiedSecurities`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    // create security
    insertIntoSecurity(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`insertIntoSecurity`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    securityTickerExist(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`securityTickerExist`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    securityTickerExistWithID(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`securityTickerExistWithID`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    securityIsinExist(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`securityIsinExist`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    securityIsinExistWithID(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`securityIsinExistWithID`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    checkVerifySecurity(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkVerifySecurity`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    verifySecurity(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`verifySecurity`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    checkUserCanAmendSecurityDescription(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkUserCanAmendSecurityDescription`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateAmendSecurity(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`updateAmendSecurity`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getSecuritySchedule(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getSecuritySchedule`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    checkBondCouponPayment(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkBondCouponPayment`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //trade Multiple Change Security Status Opr
    tradeMultipleChangeSecurityStatusOpr(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`tradeMultipleChangeSecurityStatusOpr`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getTaxRates(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getTaxRates`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
